import './App.css';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import Services from './Views/Services';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import Footer from './Components/Footer';
import {Fade} from "react-awesome-reveal";
import Gallery from "./Views/Gallery";
import Contact from './Views/Contact';
import SocialTree from './Views/ReviewPage';


function App() {

  const location = useLocation();

  return (
    <main className=' font-txt-font'>
      {location.pathname !== '/Reviews'? (
        <Navbar />
      ):null}
      <Fade delay={100}>
        <Routes>
          <Route path = "/" element = {<Home />} />
          <Route path = "/Services" element = {<Services />} />
          <Route path = "/Gallery" element = {<Gallery />} />
          <Route path='/Contact' element = {<Contact />} />
          <Route path='/Reviews' element =  {<SocialTree />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Fade>
      {location.pathname !== '/Reviews'? (
        <Footer />
      ):null}
    </main>
  );
}

export default App;