import {Swiper , SwiperSlide} from 'swiper/react';
import {Autoplay , Navigation} from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import ServicesData from './ServicesData';
import 'swiper/css';
import 'swiper/css/navigation';
import React , {useRef} from 'react';

export default function SwiperSer() { 
    const ChangeUrl = (url) => {
        window.location.href = `/${url}`;
    };
    const swiperRef = useRef(null);

    return (
        <div className=' relative'>
            <Swiper
            onSwiper={(swiper) => swiperRef.current = swiper}
                loop = {true}
                breakpoints={{
                    340: {
                        slidesPerView:1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    }
                }}
                autoplay = {{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // navigation = {true}
                modules={[Autoplay]}
            >
                {ServicesData.map((s) => {
                    return (
                        <SwiperSlide className='' key={s.id}>
                            <div className="lg:p-4">
                                <div onClick={() => ChangeUrl("Services")} className=" cursor-pointer flex sticky lg:hidden">
                                    <img alt="#" className=" rounded-lg h-full w-full" src={s.img} />
                                    <div className="absolute bg-black bg-opacity-30 p-1 inset-0 z-10 flex flex-col justify-end opacity-100">
                                        <div className="ml-3 text-xl text-green-300">
                                            <h1>{s.name}</h1>
                                        </div>
                                        <div className=" flex ml-4 text-sm text-white items-center space-x-1">
                                            <span className="">View More</span>
                                            <FontAwesomeIcon className=" text-sm text-gray-200" icon={faForward} />
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => ChangeUrl("Services")} className="sticky cursor-pointer lg:block hidden">
                                    <img alt="#" className="rounded-lg h-full w-full " src={s.img} />
                                    <div className=" absolute p-8 inset-0 z-10 bg-black flex flex-col justify-end opacity-0 hover:opacity-100 group ease-linear duration-500 transition-all bg-opacity-50">
                                        <div className="ml-3 text-3xl">
                                            <h1 className=' group-hover:text-green-300'>{s.name}</h1>
                                        </div>
                                        <div className=" flex ml-4 text-lg items-center font-thin space-x-1">
                                            <span className="group-hover:text-white">View More</span>
                                            <FontAwesomeIcon className=" text-sm text-gray-200" icon={faForward} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )  
};