import React , {useState} from "react";
import ServicesData from "../Components/ServicesData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark , faPlus} from "@fortawesome/free-solid-svg-icons";
import {Fade} from "react-awesome-reveal";

export default function Services(){
    const [IsClick , setIsClick] = useState(false);
    const handleBtn = (id) => {
        setIsClick((lastClick) => ({
            ...lastClick,
            [id]: !lastClick[id],
        }));
    };

    return (
        <section className = "space-y-2 lg:pb-12 ">
            <div className="bg-gpage1 bg-no-repeat bg-center bg-cover h-[300px] lg:h-[600px] relative">
                <div className=" bg-black w-full h-full bg-opacity-30"></div>
                <div className="top-8 text-white w-full absolute text-3xl h-full flex items-center justify-center cursor-pointer lg:text-6xl">
                    <h2 style={{fontFamily: "Redressed"}} className="">Services & Pricing</h2>
                </div>
            </div>
            <div className="lg:p-4 p-2  mx-auto max-w-screen-xl">
                <div className=" flex flex-col lg:space-y-14">
                    {ServicesData.map((s, index) => (
                        <div className="p-2 mt-5 space-y-5 md:hidden" key={s.id || index}>
                            <div className="w-full sticky top-0 font-header-font py-3 shadow-lg rounded-lg  bg-main-color/60 text-white font-semibold tracking-wide uppercase text-center" key={s.id} onClick={() => handleBtn(s.id)}>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-2 italic tracking-wider">{s.name}</div>
                                    <div>
                                        {!IsClick[s.id]?
                                            <FontAwesomeIcon className="" icon={faPlus} />: <FontAwesomeIcon className="text-white" icon={faXmark} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {IsClick[s.id] && (
                                <Fade>
                                    <div>
                                        <img className="w-full" alt = {s.name} src={s.img} />
                                        {s.services.map((se , itm) => (
                                            <div className="p-2 space-y-4" key={itm}>
                                                <div className="text-black flex flex-col gap-4">
                                                    <div className="flex flex-col space-y-4">
                                                        <span style={{fontFamily: "Redressed"}} className="text-xl">{index + 1}. {se.name}</span>
                                                        {se.desc1 && (
                                                            <span className=" text-main-color text-lg">{se.desc1}</span>
                                                        )}
                                                        <span className=" text-justify">{se.desc}</span>
                                                        {se.extra &&(
                                                           <div className=" txt-justify text-gray-600">
                                                                {se.extra.map((s1) => (
                                                                    <li>{s1}</li>
                                                                ))}
                                                           </div>
                                                        )}
                                                    </div>
                                                    <div className=" flex justify-center items-center">
                                                        <span className=" text-rose-400  text-lg font-txt-font tracking-wider font-bold">{se.price}</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>
                                        ))}                                             
                                    </div>
                                </Fade>
                            )}
                        </div>
                    ))}
                </div>
                    {ServicesData.map((ser) => (
                        <Fade>
                            <div  key={ser.id} className="p-3 space-y-8 hidden md:block">
                                <div id={ser.id} className="flex justify-center">
                                    <div className="bg-main-color/80 rounded-full shadow-md flex flex-col  px-12 py-3">
                                        <h1 style={{fontFamily: "Redressed"}} className="tracking-wide capitalize cursor-pointer italic text-white md:text-4xl font-bold">{ser.name}</h1>
                                    </div>
                                </div>
                                <div className="p-4 cursor-pointer">
                                    <div className=" grid gap-4 grid-cols-4">
                                        {ser.services.map((s , index) => (
                                            <div key={index} className="shadow-lg h-full rounded-lg mb-2 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                                <div className="px-8 space-y-4 mb-4 mt-4">
                                                    <h1 className="md:text-lg font-header-font text-center capitalize border-main-color/40 border-b-2 py-1">{s.name}</h1>
                                                    <div className="text-sm flex space-y-2 flex-col font-txt-font text-rose-400 tracking-wide text-center">
                                                        <div className="text-justify text-black/80">
                                                            {s.desc}
                                                        </div>
                                                        {s.extra &&(
                                                           <div className=" text-sm text-gray-600">
                                                                <ul className="space-y-2 flex flex-col justify-center mt-2 list-outside list-decimal">
                                                                    {s.extra.map((s1) => (
                                                                        <li>{s1}</li>
                                                                    ))}
                                                                </ul>
                                                           </div>
                                                        )}
                                                        {( (s.desc1 !== '') || (s.desc1)) &&(
                                                           <div className=" text-lg text-main-color">
                                                            {s.desc1}
                                                           </div>
                                                        )}
                                                        <div className=" text-xl">
                                                            {s.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    ))}
            </div>
        </section>
    )
};









































































