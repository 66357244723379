import React from "react";
import spapic from "../img/home/9.png"
export default function Contact(){

    return (
        <section className = "space-y-5 pb-5 md:pb-12 ">
            <div className="bg-gpage3 bg-no-repeat bg-center bg-cover h-[300px] lg:h-[600px] relative">
                <div className=" bg-black w-full h-full bg-opacity-30"></div>
                <div className="top-8 text-white w-full absolute text-3xl h-full flex items-center justify-center cursor-pointer lg:text-6xl">
                    <h2 style={{fontFamily: "Redressed"}} className="">Contact Us</h2>
                </div>
            </div>
            <div className="mx-auto md:max-w-screen-xl cursor-pointer">
                <div className="grid grid-cols-1 text-justify md:gap-8 lg:grid-cols-3 ">
                    <div className="col-span-2 md:w-full w-screen p-4 space-y-4 h-full lg:space-y-8 transition ease-in-out duration-200 delay-100 hover:opacity-50">
                        <img className=" h-full" src={spapic} alt="" />
                    </div>
                    <div className="p-4 space-y-4 lg:space-y-8 lg:text-justify lg:grid justify-center text-center">
                        <div className="">
                            <div className="grid grid-cols-1 p-4 space-y-5">
                                <div>
                                    <span className="text-3xl tracking-wider">See us in person</span>
                                </div>
                                <div className="space-y-3">
                                   <p className="font-newFont">We love our customers and welcome them to visit during our normal business hours of 10am to 10:30pm, Monday through Friday.</p>
                                   <ul className="space-y-2 font-semibold">
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" target="_blank"  rel="noreferrer" href = "https://maps.app.goo.gl/Fx29pNyXWQzrCaGs8">
                                                Address: 5960 W Parker Rd Ste 270, Plano, TX 75093
                                            </a>
                                        </li>
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" href="tel:9723787827">
                                                Phone#: (972) 378-7827
                                            </a>
                                        </li>
                                   </ul>
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="font-semibold text-lg">Business Hours:</div>
                                    <ul className="space-y-4 text-sm mt-4">
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Mon: 10:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Tues:  10:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Wed:  10:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Thu:  10:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Fri:  10:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sat: 9:00 am - 10:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span href="" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sun: 12:00 pm - 10:30 pm
                                            </span>
                                        </li>
                                    </ul>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.fresha.com/a/lash-nail-bar-plano-5960-west-parker-road-a9navmfj/booking?allOffer=true&menu=true', '_blank');
                                    }} className=" mt-5 py-3 transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color px-12 border-[1px]">
                                       <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                            Book Now
                                       </span>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid justify-items-center">
                <iframe className="w-full md:px-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.4632130898676!2d-96.82750999999999!3d33.0442698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c236690e77cdd%3A0x60a7b77e3769608a!2sLash%20Nail%20Bar!5e0!3m2!1sen!2sus!4v1715582106000!5m2!1sen!2sus"  height="600" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    )
}