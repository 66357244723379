import React from "react";
import CarouselHome from "../Components/HomeCarousel";
import img1 from "../img/about/1.png";
import SwiperSer from "../Components/SwiperSer";
import serimg from "../img/img_treat.png";
import ServicesData from "../Components/ServicesData";
import ser4 from "../img/bg-img.png";
import ser5 from "../img/img2.png";
import img2 from "../img/ser1.png";
import img3 from "../img/ser2.png";
import home3 from "../img/home/home_3.JPG";
import Popup from "../Components/Popup";

export default function Home(){
    return (
        <>
            <Popup />
            <section className="relative overflow-x-hidden">
                <CarouselHome />
                <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                            {/* <Fade> */}
                                <div className=" flex flex-col lg:space-y-8">
                                    <img alt="da" src={img1} />
                                    <div className=" pb-8"></div>
                                    <img alt="da" className="ml-4" src={img3} />
                                </div>
                            {/* </Fade> */}
                            <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                                <span style={{fontFamily: 'Redressed'}} className=" text-2xl text-black/40">About us</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-header-font text-black">
                                        Revel in your innate charm with a novel
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Outlook
                                    </span>
                                </div>
                                <p className=" text-xl text-black/60 font-txt-font">
                                    Experience top-tier nail and lash care at Lash Nail Bar in Plano, TX. 
                                    Enjoy luxury treatments from skilled technicians in our modern, elegant salon. Visit us for exceptional service and beautiful results.
                                </p>
                                <div className="">
                                    <button onClick={() => {
                                        window.location.href = 'https://www.fresha.com/a/lash-nail-bar-plano-5960-west-parker-road-a9navmfj/booking?allOffer=true&menu=true';
                                    }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                        <span className="">Book Now</span>
                                    </button>
                                </div>
                            </div>
                            {/* <Fade> */}
                                <div className=" flex flex-col lg:space-y-8">
                                    <img alt="da" className="" src={img2} />
                                    <div className=" pb-8"></div>
                                    <img alt="da" className="ml-4" src={home3} />
                                </div>
                            {/* </Fade> */}
                        </div>
                    </div>
                </div>
                <div className="lg:grid hidden lg:grid-cols-2">
                    {/* <img alt="da"  className=" cursor-pointer h-[600px] w-full" src= {img3} /> */}
                    {/* <img alt="da"  className=" h-[600px] cursor-pointer  w-full" src={img4} /> */}
                </div>
                <div className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#faf3ee]">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className="flex flex-col text-center space-y-8">
                            <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Our Services</span>
                            <div className=" flex flex-col space-y-4">
                                <span className=" text-4xl font-header-font text-black">
                                    Embrace your unique allure with a fresh
                                </span>
                                <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                    Perspective
                                </span>
                            </div>
                        </div>
                        <div>
                            <SwiperSer />
                        </div>
                    </div>
                </div>
                <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-white">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            {/* <Fade> */}
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Discover Excellence</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-black">
                                            Expert pampering and luxurious
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Treatments
                                        </span>
                                    </div>
                                    <span className=" text-lg text-black/80 font-txt-font">
                                        Choose Lash Nail Bar for expert pampering and luxurious treatments. 
                                        Our Plano salon offers top-quality beauty services in a tranquil setting, 
                                        ensuring you feel refreshed and radiant with each visit.
                                    </span>
                                    <div className=" font-txt-font">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className="">
                                                        Expertise and Precision
                                                    </span>
                                                </div>
                                                <span className=" text-black/60 ml-8 text-lg font-thin">
                                                    Experience premier nail care by our highly skilled technicians who are committed to perfection.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className="">
                                                        Luxurious Pampering
                                                    </span>
                                                </div>
                                                <span className=" text-black/60 ml-8 text-lg font-thin">
                                                    Escape the daily grind with indulgent relaxation and luxury at our salon. Every visit rejuvenates your spirit and enhances your beauty.                                        
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className="">
                                                        Personalized Service
                                                    </span>
                                                </div>
                                                <span className=" text-black/60 ml-8 text-lg font-thin">
                                                    Our tailor-made treatments are designed to meet your unique needs in a serene environment, leaving you thoroughly pampered and satisfied."                                        
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            {/* </Fade> */}
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg} />
                            </div>
                            <div className="hidden lg:block lg:bg-bg-bgser lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-[#faf3ee]">
                    <div className="mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-12 lg:grid-cols-2">
                            {/* <JackInTheBox> */}
                                <div className=" h-auto">
                                    <img src={ser4} alt="da" />
                                </div>
                            {/* </JackInTheBox> */}
                            {/* <Fade> */}
                                <div className="flex lg:p-8 flex-col space-y-6">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Pricing</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-black">
                                            Transparent pricing, premium
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Services
                                        </span>
                                    </div>
                                    <span className=" text-lg text-black/60 font-txt-font">
                                        Discover transparent pricing tailored to your beauty needs.
                                        Explore our competitive rates and indulge in premium services without breaking the bank.
                                    </span>
                                    {ServicesData.slice(0,5).map((s) => (
                                        s.services.slice(0,1).map((ser , index) => (
                                            <>                                    
                                                <div key={index} className=" text-2xl flex w-full justify-between">
                                                    <div  style={{fontFamily: 'Calligraffitti'}} className=" flex space-y-2 flex-col">
                                                        <span>{ser.name}</span>
                                                    </div>
                                                    <span className="font-header-font">{ser.price}</span>
                                                </div>
                                                <hr></hr>
                                            </>
                                        ))
                                    ))}
                                    <div className=" flex justify-center">
                                        <button onClick={() => {
                                            window.location.href = '/Services';
                                        }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                            <span className="">View More</span>
                                        </button>
                                    </div>
                                </div>
                            {/* </Fade> */}
                        </div>
                    </div>
                </div>
                    <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-white">
                        <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                            <div className=" grid gap-8 lg:grid-cols-2">
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Contact Us</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-black">
                                            Reach out for personalized beauty
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Solutions
                                        </span>
                                    </div>
                                    <span className=" text-lg text-black/80 font-txt-font">
                                        Get in touch effortlessly. Reach out today for personalized beauty 
                                        solutions and exceptional service.
                                    </span>
                                    <div className=" font-txt-font">
                                        <ul className="space-y-8 text-xl">
                                            <li>
                                                <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:9723787827">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-12 w-12 shrink-0 text-main-color"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                        />
                                                    </svg>
                                                    <span className="flex-1 text-black">(972) 378-7827</span>
                                                </a>
                                            </li>
                                            <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-12 w-12 shrink-0 text-main-color"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                                <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/Fx29pNyXWQzrCaGs8" className="-mt-0.5 flex-1 not-italic text-black">
                                                5960 W Parker Rd Ste 270 Plano, TX 75093
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className=" lg:hidden flex">
                                    <img alt="da" src={ser5} />
                                </div>
                                <div className="hidden lg:block lg:bg-bgser1 lg:w-full lg:h-auto lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}