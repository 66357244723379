import React from "react";
import img1 from "../img/home/12.png";
import img2 from "../img/home/13.png";
import img3 from "../img/home/14.png";
import img4 from "../img/home/15.png";
import img5 from "../img/home/16.png";
import img6 from "../img/home/17.png";
import img7 from "../img/home/18.png";
import img8 from "../img/home/19.png";
import img9 from "../img/home/20.png";
import img10 from "../img/home/21.png";

const ServicesData = [
    {
        id: 1,
        name: 'Manicures',
        img: img1,
        services: [
            {
                name: 'Gold Manicure',
                price: '$45',
                desc: "Indulge the most luxurious manicure by rejuvenating and hydrating your skin with freshly cut cucumbers along with organic mask. Followed by hot stones reflexology massage on hands, arms, neck and shoulders in addition, and finished with a polish of choice."
            },
            {
                name: 'Heavenly Manicure',
                price: '$40',
                desc: "Immerse yourself into the ultimate experience with sea salt scrub and hydrating Milk & Honey Butter to smooth and soften rough skin. Followed by hot stones reflexology massage on hands, arms, neck and shoulders in addition, and finished with a polish of choice."
            },
            {
                name: 'Deluxe Manicure',
                price: '$30',
                desc: "Our popular pick manicure. Benefits of classic manicure plus Parafin Wax"
            }, 
            {
                name: 'Basic Manicure',
                price: '$20',
                // desc1: "Add Gel: $20",
                desc: "Our standard manicure consists of natural nail care, cuticle removal, refresh ith hot towel and hand massage, your choice of polish."
            },
        ],
    },
    {
        id: 2,
        name: 'Pedicure',
        img: img2,
        services: [
            {
                name: 'Gold CBD Pedicure',
                price: '$85',
                desc1: '30  min foot & 10 min shoulder massage',
                desc: "Enjoy the most luxurious pedicure utilizing products from CEREMONY OF GOLD which includes a gold soak, scrub, and mask. The extremely rejuvenating pedicure with 24K gold ingredients to aid the slowdown of collagen depletion, increasing of skin elasticity, and stimulating cells to the help tone up the skin. Gold soak with herbal infused foot bath + neck therapy + collagen foot mask + 30-minute feet and shoulder massage with hot stones.",
            },
            {
                name: 'Detox Pedicures',
                price: '$75',
                desc1: '30  min foot & 5 min shoulder massage',
                desc: "One of our most enjoyable pedicures. Includes a charcoal soak, purifying scrub, nourishing mask, and neck therapy. This is followed by paraffin wax treatment and a 25-minute feet and shoulder massage using hot stones and emollient massage cream to soften and soothe. ",
            },
            {
                name: 'Heavenly Collagen Pedicure',
                price: '$69',
                desc1: '25  min foot & 5 min shoulder massage',
                desc: "Immerse yourself into the ultimate experience. your treatment begins with a foot grooming ritual, followed by a stimulating sea salt exfoliation to help smooth and soften rough skin. Our foot mask and hydrating Milk & honey butter incorporates warm towels to infuse moisture deep into the relax skin. followed by the 25 minutes reflexolo, massage that will releive stress, tension and soreness leaving you feeling completely rejuvenated",
                // extra: ["Milk and Honey/ cocohoney" , "Cucumber / Aloe Vera" , "Citrus"]
            },
            {
                name: 'Cosmo Spa Pedicure',
                price: '$59',
                desc1: '20 minute foot massage',
                desc: "Treat your feet to an unforgettable experience of pure pampering that softens the skin, eliminates dryness and deeply relax you, The pedicure begins with a footbath, followed by cuticles trim and grooming. The extensive call removal and GREENTEA sea salt exfoliation is included to help soften rough skin. Our foot mask incorporates witl warm towels to infuse moisture into the relaxed skin. Parafin wax and hot stone massage is providing silky smoot skin.",
            },
            {
                name: 'Fresh Aloe Vera',
                price: '$55',
                desc1: '15 minute foot massage',
                desc: "Designed to moisturize, revitalize and naturally hydrate the skin. Olive has been known as an intensive but gentle conditioner. This pedicure is full of vitamin E. Hot stone massage. A warm paraffin treatment seals in moisture and provides complete hydration; leaving you renewed, soften with silky smooth skin.",
            },
            {
                name: 'Fresh Cucumber',
                price: '$48',
                desc1: '12 minute massage',
                desc: "Our summer Cucumber pedicure is a symphony of essential oils, vitamin E, and antioxidants formulated to stimulate your senses as it smooth, refines, and conditions your feet. A CUCUMBER rub follows to wipe dead skin and restore moisture. It provides rich vitamins and minerals to give naturally radiant skin with strong healthy nails. Hot stone massage is providing.",
            },
            {
                name: 'Lavender Pedicure',
                price: '$39',
                desc: "Soak, nail shaping, filling, cuticle trim, buffing ,Callus and Heel Treatment",
            },
            {
                name: 'Basic Pedicure',
                price: '$35',
                desc: "Soak, nail shaping, filling, cuticle trim, buffing",
            },
        ],
    },
    {
        id: 3,
        name: 'Full Sets',
        img: img3,
        services: [
            {
                name: "Acrylic/Fill",
                price: '$45+/$40+',
            },
            {
                name: "Solar Pink & White / Fill",
                price: '$55+/$50+',
            },
            {
                name: "Color Powder / Fill",
                price: '$50+/$45+',
            },
            {
                name: "Pink Fill Only",
                price: '$40+',
            }
        ],
    },
    {
        id: 4,
        name: 'Nex-Gen & Dipping Powder',
        img: img4,
        services: [
            {
                name: 'Dip Color',
                price: '$42+',
                
            },
            {
                name: 'Pink & White',
                price: '$55+',
                
            },
            {
                name: 'Ombre',
                price: '$65+',
                
            },
            {
                name: 'Add Tips',
                price: '$5+',
                
            },
            {
                name: 'Shape',
                price: '$5',
            },
            {
                name: 'Length',
                price: '$5+',
            },
        ],
    },
    {
        id: 5,
        name: 'Facial',
        img: img5,
        services: [
            {
                name: 'Deep Cleansing',
                price: '$70',
                desc: "This treatment includes professional double cleansing, exfoliation, extractions, masque, hydration and solar defense. Additional products and therapies may be added to target the individual needs of your skin."
            },
            {
                name: 'Anti-Acne Facial',
                price: '$80',
                desc: "Works to treat clear and prevent adult acne while addressing the needs of adult skin. Anti-Acne Facial controls acne without compromising skin's integrity, which means skin is clearer and healthier."
            },
            {
                name: 'Marine Essential',
                price: '$80',
                desc: "Nutrient dense essentials to transform your skin. It features a complete regimen to firm, smooth and help control the signs of skin aging at the source."
            }
        ],
    },
    {
        id: 6,
        name: 'Gel Services',
        img: img6,
        services: [
            {
                name: 'Basic Mani + Gel Color/French',
                price: '$40/$47',
                
            },
            {
                name: 'Toe Gel/French W/Out Service',
                price: '$30/$37',
                
            },
            {
                name: 'Hand Gel/French W/Out Service',
                price: '$25/$32',
                
            },
            {
                name: 'Gel Removal W/Out Service',
                price: '$10',
                
            },
        ],
        desc: ''
    },
    {
        id: 7,
        name: "Kid's Menu",
        img: img7,
        desc: '',
        services: [
            {
                name: 'Pedicure',
                price: '$22',
            },
            {
                name: 'Manicure',
                price: '$12', 
            },
            {
                name: 'Hand Polish Only',
                price: '$6', 
            },
            {
                name: 'Toe Polish Only',
                price: '$10', 
            },
        ]
    },
    {
        id: 8,
        name: 'Waxing',
        services: [
            {
                name: 'Eyebrow',
                price: '$12'
            },
            {
                name: 'Lip',
                price: '$10'
            },
            {
                name: 'Chin',
                price: '$15+'
            },
            {
                name: 'Cheeks',
                price: '$14'
            },
            {
                name: 'Full Face',
                price: '$43'
            },
            {
                name: 'Full Legs',
                price: '$65+'
            },
            {
                name: 'Upper Legs',
                price: '$45+'
            },
            {
                name: 'Lower Legs',
                price: '$40'
            },
            {
                name: 'Bikini',
                price: '$40'
            },
            {
                name: 'Brazilian',
                price: '$55+'
            },
            {
                name: 'Chest',
                price: '$50+'
            },
            {
                name: 'Back',
                price: '$55+'
            },
            {
                name: 'Half Arms',
                price: '$40+'
            },
            {
                name: 'Full Arms',
                price: '$55+'
            },
            {
                name: 'Under Arms',
                price: '$25'
            }
        ],
        img: img8,
    },
    {
        id: 9,
        name: 'Eyelash Extensions',
        services: [
            {
                name: 'Full Set',
                price: '$150'
            },
            {
                name: 'Re-fill',
                price: '$60-$80'
            }
        ],
        img: img9,
    },

    {
        id: 10,
        name: 'Extras',
        services: [
            {
                name: 'Naiils Design',
                price: '$5+ each'
            },
            {
                name: 'Nails Repair',
                price: '$7+'
            },
            {
                name: 'Parafin Wax',
                price: '$7'
            },
            {
                name: 'Collagen Mask',
                price: '$10'
            },
            {
                name: 'Hand Polish/French Change',
                price: '$10/$15'
            },
            {
                name: 'Feel Polish/French Change',
                price: '$12/$16'
            },
            {
                name: 'Nail Remove W/Out Service',
                price: '$10+'
            },
        ],
        img: img10,
    }
];

export default ServicesData;